import mpinProgress from "../../../assets/images/mpinProgress.png";
import { LanguageContext } from "../../../context/LanguageContext";
import React, { useEffect, useState, useContext } from "react";
import { getDepositDetails } from "../../../SyncServices";
import { logEvent } from "@firebase/analytics";
import { analytics } from "../../../firebase";
import { ParagraphText } from "../../common";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import "./style.css";

const DURATION = "120";

const MpinScreen = ({
  toggleMpinScreen,
  setReferenceId,
  setPayDetails,
  setMpinSend,
  payDetails,
  mpinSend,
  method,
}) => {
  const [I18n] = useContext(LanguageContext);

  const [seconds, setSeconds] = useState(DURATION);

  useEffect(() => {
    if (mpinSend) {
      handleVerifyMPIN();
      let s = seconds;

      const interval = setInterval(() => {
        s--;

        if (s === 0) {
          setSeconds(Number(DURATION));
          clearInterval(interval);
          setMpinSend(false);
          return;
        }

        if (s < 10) {
          s = "0" + s;
        }

        setSeconds(s);
      }, 1000);
    }
  }, [mpinSend]);

  const handleVerifyMPIN = () => {
    try {
      if (navigator.onLine) {
        let intr = setInterval(() => {
          getDepositDetails({ unique_code: payDetails?.unique_code }).then((res) => {
            const { message, payment_status, reference_id } = res;

            setPayDetails({ ...res, ...payDetails });

            if (payment_status === "paid") {
              clearInterval(intr);
              toggleMpinScreen(true);
              setReferenceId(reference_id);
              logEvent(analytics, "payment_successful", {});
            } else if (payment_status == "failed") {
              toggleMpinScreen();
              clearInterval(intr);
              toast.error(message || "An error occured");
            }
          })
            .catch((err) => {
              toggleMpinScreen();
              toast.error(
                err?.response?.status == 503
                  ? "Service not available. Please try again later."
                  : err.response?.data?.message
              );
              clearInterval(intr);
            });
        }, 5000);
      }
    } catch (e) { }
  };

  return (
    <>
      <h1 className="fontSize26 fontWeight700 darkGrayColor textCenter marBot15">
        {I18n.verify_mpin}
      </h1>
      <ParagraphText className="fontSize14 fontWeight400 darkGrayTwoColor marBot20 textCenter">
        <span>{method == "jazzcash" ? I18n.JazzCash : I18n.EasyPaisa}</span>
        <span className="marLeft5">{I18n.verify_mpin_desc} </span>
      </ParagraphText>
      <ParagraphText className="fontSize14 fontWeight400 darkGrayTwoColor marBot20 textCenter">
        <span>
          {Math.floor(seconds / 60) == 0
            ? "00"
            : "0" + Math.floor(seconds / 60)}
          :
          {seconds - Math.floor(seconds / 60) * 60 < 10
            ? "0" + (seconds - Math.floor(seconds / 60) * 60)
            : seconds - Math.floor(seconds / 60) * 60}
        </span>
        <span className="waitingLoad textCenter marBot20">
          <Spinner animation="border" className="loaderCircle" />
        </span>
      </ParagraphText>

      <div className="BodyContentTop paymentFieldSection mpinScreen">
        <div className="mPinText mPinImageBox">
          <img
            className="mpinScreenImage"
            src={mpinProgress}
            title="mPin Image"
            alt="mPin Imag"
          />
        </div>

        <div className="trustedBusiness mPinTrustBox">
          <ParagraphText className="fontSize14 darkGrayColor">
            <span>
              <i className="safesecurIcon"></i>
            </span>
            <span>3.4M+ businesses ka trust</span>
          </ParagraphText>
        </div>
      </div>
    </>
  );
};

export default MpinScreen;
