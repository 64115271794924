export default {
  languageType: "en",
  lang: "en",
  bill_from: "Bill from",
  pay_with_bank: "Pay with bank transfer by",
  earn_bonus: "& earn 1% bonus",
  pay_with_bank_earn_bonus: "Pay with bank transfer by & earn 1% bonus.",
  bill_Date: "Bill Date",
  by_confirmuing_your_payment_you_allow:
    "By confirming your payment, you allow,",
  to_charge_for_this: "to charge for this payment.",
  select_payment_method: "Select payment method",
  account_information: "Account Information",
  country: "Country",
  verify: "Verify",
  apko_code_sms_kar_diya_hai: "Apko code SMS kar diya hai",
  waiting_for_otp_code: "Waiting for OTP code",
  waiting_for_mpin_confirmation: "Waiting for MPIN confirmation",
  apna: "Apna",
  app_kholein_aur_pin_enter_karein: "App Kholein Aur PIN Enter Karein",
  waiting_for_you_to_verify_easyload_deposot_amount:
    "Waiting For You To Verify EasyLoad Deposit Amount On.",
  app_needs_to_verify_your_easyload_amount:
    "app needs to verify your easyload amount",
  four_digit_code_eneter: "4 digit code enter karein",
  kia_code_nhi_mila_abhi_resend_code:
    "Kia code nahi mila? Abhi resend code karein",
  resend_code: "Resend Code",
  apne_mobile_networl_change: "Apne apna mobile network change kia hai?",
  apna_current_network_select_karein: "Apna current network select karein",
  please_try_again: "Please try again",
  enter: "Enter",
  number: "number",
  pakistan: "Pakistan",
  invalid_code: "Invalid code",
  EasyPaisa: "Easypaisa",
  JazzCash: "Jazzcash",
  phone_number_format: 'Phone number should be in format e.g. 03001234567',
  sms_wait_time: "SMS code ka wait time",
  verify_mpin: "Verify MPIN",
  verify_mpin_desc: "app se Mpin verify karein",
  months: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
};
